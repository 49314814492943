import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import createUserValidation from 'utils/validation/registers/users/createUserValidation';
import userService from 'services/userService';
import StyledUserForm from '../userFormStyle';
import { Autocomplete, Dialog, TextField } from '@mui/material';
import { alertError } from 'utils/helper/appHelper';
import { RequireSign } from 'components/common';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        overflowY: 'visible',
    },
}));
export const roleOptions = [
    {
        label: '管理者',
        value: 'admin',
    },
    {
        label: '一般ユーザ',
        value: 'member',
    },
];
const CreateNewUser = ({
    open,
    setOpen,
    onFinish,
    workerOptions,
    lineFriendsOptions,
    isOpenWorker,
    setIsOpenWorker,
    isOpenLineBotFriend,
    setIsOpenLineBotFriend,
    readOnly,
    setReadOnly,
    isOpenRole,
    setIsOpenRole,
}) => {
    const { t } = useTranslation();
    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm();

    const handleClose = () => {
        setOpen(false);
    };

    const onCreate = async (input) => {
        // input.worker_id = +input.worker.value;
        try {
            const data = await userService.createUser({
                ...input,
                worker_id: input?.worker?.value,
                line_bot_friend_id: input?.line_bot_friend_id?.value,
                role: input?.role?.value,
            });
            if (data) {
                onFinish();
            }
        } catch (error) {
            const errorResponse = error.response;
            alertError(errorResponse.data?.error);
            setOpen(false);
        }
    };

    return (
        <BootstrapDialog open={open} onClose={handleClose}>
            <StyledUserForm onSubmit={handleSubmit(onCreate)}>
                <h2 className="user-form-title">アカウント新規作成</h2>

                <div className="user-form-field">
                    <p>
                        <RequireSign />
                        email</p>
                    <input
                        type="email"
                        autoComplete="off"
                        // required
                        {...register('email', createUserValidation(t).email())}
                        readOnly={readOnly}
                        onFocus={() => setReadOnly(false)}
                        onBlur={() => setReadOnly(true)}
                    ></input>
                    {errors.email && (
                        <span className="error">
                            {errors.email.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="user-form-field">
                    <p><RequireSign /> パスワード</p>
                    <input
                        type="password"
                        autoComplete="off"
                        // required
                        {...register(
                            'password',
                            createUserValidation(t).password(),
                        )}
                    ></input>
                    {errors.password && (
                        <span className="error">
                            {errors.password.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="user-form-field">
                    <p>パスワード再入力</p>
                    <input
                        type="password"
                        autoComplete="off"
                        // required
                        {...register('password_confirmation', {
                            required: true,
                            validate: (val: string) => {
                                if (watch('password') != val) {
                                    return 'このパスワードは合いません';
                                }
                            },
                        })}
                    ></input>
                    {errors.password_confirmation && (
                        <span className="error">
                            {errors.password_confirmation.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="user-form-field">
                    <p><RequireSign />社員</p>
                    <Controller
                        name="worker"
                        control={control}
                        render={({ field: { value } }) => (
                            <Autocomplete
                                open={isOpenWorker}
                                onOpen={() => setIsOpenWorker(true)}
                                onClose={() => setIsOpenWorker(false)}
                                onFocus={() => setIsOpenWorker(true)}
                                sx={{
                                    border: '1px solid #CDD1D5',
                                    borderRadius: '8px',
                                }}
                                disablePortal
                                {...register(
                                    'worker',
                                    createUserValidation(t).worker(),
                                )}
                                options={workerOptions.map((item) => ({
                                    label: item.label,
                                    value: String(item.value),
                                }))}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                onChange={(event, newValue) => {
                                    setValue('worker', newValue);
                                }}
                                classes={{
                                    root: 'autocomplete-select-style',
                                }}
                                value={{
                                    label: value?.label || '',
                                    value: value?.value || '',
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label=""
                                        className="autocomplete-custom-textfield"
                                    />
                                )}
                                noOptionsText="該当なし"
                            />
                        )}
                    />
                    {errors.worker && (
                        <span className="error">
                            {errors.worker.message?.toString()}
                        </span>
                    )}
                </div>
                {/* Ẩn trường 友達リスト表示 */}
                {/* <div className="user-form-field">
                    <p>友達リスト表示</p>
                    <Controller
                        name="line_bot_friend_id"
                        control={control}
                        render={({ field: { value } }) => (
                            <Autocomplete
                                open={isOpenLineBotFriend}
                                onOpen={() => setIsOpenLineBotFriend(true)}
                                onClose={() => setIsOpenLineBotFriend(false)}
                                onFocus={() => setIsOpenLineBotFriend(true)}
                                sx={{
                                    border: '1px solid #CDD1D5',
                                    borderRadius: '8px',
                                }}
                                disablePortal
                                // {...register(
                                //     'worker',
                                //     createUserValidation(t).worker(),
                                // )}
                                options={lineFriendsOptions.map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                }))}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                onChange={(event, newValue) => {
                                    console.log(newValue);
                                    setValue('line_bot_friend_id', newValue);
                                }}
                                classes={{
                                    root: 'autocomplete-select-style',
                                }}
                                value={{
                                    label: value?.label || '',
                                    value: value?.value || '',
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label=""
                                        className="autocomplete-custom-textfield"
                                    />
                                )}
                                noOptionsText="該当なし"
                            />
                        )}
                    />
                </div> */}
                <div className="user-form-field">
                    <p><RequireSign /> 権限</p>
                    <Controller
                        name="role"
                        control={control}
                        render={({ field: { value } }) => (
                            <Autocomplete
                                open={isOpenRole}
                                onOpen={() => setIsOpenRole(true)}
                                onClose={() => setIsOpenRole(false)}
                                onFocus={() => setIsOpenRole(true)}
                                sx={{
                                    border: '1px solid #CDD1D5',
                                    borderRadius: '8px',
                                }}
                                disablePortal
                                {...register(
                                    'role',
                                    createUserValidation(t).role(),
                                )}
                                options={roleOptions.map((item) => ({
                                    label: item.label,
                                    value: String(item.value),
                                }))}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                onChange={(event, newValue) => {
                                    setValue('role', newValue);
                                }}
                                classes={{
                                    root: 'autocomplete-select-style',
                                }}
                                value={{
                                    label: value?.label || '',
                                    value: value?.value || '',
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label=""
                                        className="autocomplete-custom-textfield"
                                    />
                                )}
                                noOptionsText="該当なし"
                            />
                        )}
                    />
                    {errors.role && (
                        <span className="error">
                            {errors.role.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="user-form-button-group">
                    <button
                        className="return-button"
                        type="button"
                        onClick={handleClose}
                    >
                        戻る
                    </button>
                    <button className="register-button" type="submit">
                        登録
                    </button>
                </div>
            </StyledUserForm>
        </BootstrapDialog>
    );
};

export default CreateNewUser;
