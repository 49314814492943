import { StylesCreateMeetingMinutes } from './styled';
import { ReceivedProps } from '../type';
import useCreateMeetingMinutes, { Props } from './hook';
import { Button } from '@mui/material';
import uploadSimple from 'assets/icons/UploadSimple.svg';
import SignatureCanvas from 'react-signature-canvas';
import { Checkbox } from 'antd';
import { Delete } from '@mui/icons-material';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RequireSign } from 'components/common';

const CreateInternalOJTLayout = ({
    formik,
    signatureRef,
    meetingMinutes,
    navigate,
    handleFileUpload,
    handleFileUploadMeetingImage,
    handleRemoveMeetingFile,
    handleFileUploadMeetingFile,
    handleRemoveMeetingImage,
    setRemovedFiles,
    isCheckAgree,
    setIsCheckAgree,
    id,
}: Props) => {
    return (
        <StylesCreateMeetingMinutes>
            <p className="title">{!id ? '議事録新規作成' : '議事録編集'}</p>
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col mb-[14px]">
                    <label><RequireSign /> タイトル</label>
                    <input
                        disabled={meetingMinutes?.is_signed || isCheckAgree}
                        className="max-w-[342px]"
                        name="subject"
                        value={formik.values.subject}
                        onChange={(e) =>
                            formik.setFieldValue('subject', e.target.value)
                        }
                    />
                    {formik.touched.subject && formik.errors.subject && (
                        <span className="error">{formik.errors.subject}</span>
                    )}
                </div>
                <div className="flex flex-col mb-[14px]">
                    <label><RequireSign /> ファイル</label>
                    <Button
                        component="label"
                        disabled={meetingMinutes?.is_signed || isCheckAgree}
                        className="uploadImageFileBtn"
                        style={{
                            width: 'fit-content',
                            backgroundColor: '#215493',
                            color: '#FFFFFF',
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            padding: '10px 18px',
                            height: '36px',
                            borderRadius: '6px',
                        }}
                    >
                        <img
                            src={uploadSimple}
                            alt="upload icon"
                            style={{ marginRight: '7px' }}
                        />
                        <span>ファイルアップロード</span>
                        <input
                            disabled={meetingMinutes?.is_signed || isCheckAgree}
                            multiple
                            type="file"
                            name="meeting_file"
                            hidden
                            style={{
                                display: 'none',
                            }}
                            onChange={handleFileUploadMeetingFile}
                        />
                    </Button>
                    {formik?.values?.meeting_file?.length > 0 &&
                        formik?.values?.meeting_file?.map((file, index) => (
                            <div className="flex pt-2 items-center">
                                <p className="mb-0">{file?.name || ''}</p>
                                {!meetingMinutes?.is_signed && (
                                    <Delete
                                        onClick={() =>
                                            handleRemoveMeetingFile(index, file)
                                        }
                                        fontSize="small"
                                        sx={{
                                            color: '#FF5045',
                                            cursor: 'pointer',
                                        }}
                                    />
                                )}
                            </div>
                        ))}

                    {formik.touched.meeting_file &&
                        formik.errors.meeting_file && (
                            <span className="error">
                                {formik.errors.meeting_file?.toString()}
                            </span>
                        )}
                </div>
                <div className="flex flex-col mb-[14px]">
                    <label><RequireSign /> 画像</label>
                    <Button
                        disabled={meetingMinutes?.is_signed || isCheckAgree}
                        component="label"
                        className="uploadImageFileBtn"
                        style={{
                            width: 'fit-content',
                            backgroundColor: '#215493',
                            color: '#FFFFFF',
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            padding: '10px 18px',
                            height: '36px',
                            borderRadius: '6px',
                        }}
                    >
                        <img
                            src={uploadSimple}
                            alt="upload icon"
                            style={{ marginRight: '7px' }}
                        />
                        <span>画像アップロード</span>
                        <input
                            disabled={meetingMinutes?.is_signed}
                            multiple
                            type="file"
                            name="meeting_image"
                            hidden
                            style={{
                                display: 'none',
                            }}
                            onChange={handleFileUploadMeetingImage}
                            accept="image/*"
                        />
                    </Button>
                    {formik?.values?.meeting_image?.length > 0 &&
                        formik?.values?.meeting_image?.map((file, index) => (
                            <div className="flex pt-2 items-center">
                                <p className="mb-0">{file?.name || ''}</p>
                                {!meetingMinutes?.is_signed && (
                                    <Delete
                                        onClick={() =>
                                            handleRemoveMeetingImage(
                                                index,
                                                file,
                                            )
                                        }
                                        fontSize="small"
                                        sx={{
                                            color: '#FF5045',
                                            cursor: 'pointer',
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    {formik.touched.meeting_image &&
                        formik.errors.meeting_image && (
                            <span className="error">
                                {formik.errors.meeting_image?.toString()}
                            </span>
                        )}
                </div>
                <div className="relative w-[343px]">
                    <div className="flex justify-between w-[343px]">
                        <label>サイン</label>
                        <div className="flex items-center justify-center">
                            <Checkbox
                                name="is_show_on_todo_list"
                                className="custom-checkbox"
                                // checked={formik.values.is_show_on_todo_list}
                                // onChange={formik.handleChange}
                                checked={isCheckAgree}
                                onChange={(e: CheckboxChangeEvent) =>
                                    setIsCheckAgree(e.target.checked)
                                }
                            />
                            <span className="text-[16px] text-[#000]">
                                承認しました
                            </span>
                        </div>
                    </div>
                    {formik.values?.signature_file?.includes('https') ? (
                        <div className="sigCanvas flex items-center justify-center w-[343px] h-[180px]">
                            <img src={formik.values.signature_file} />
                        </div>
                    ) : (
                        <SignatureCanvas
                            onEnd={() =>
                                formik.setFieldValue(
                                    'signature_file',
                                    signatureRef?.current
                                        ?.getTrimmedCanvas()
                                        .toDataURL(),
                                )
                            }
                            ref={signatureRef}
                            penColor="#225594"
                            canvasProps={{
                                className: 'sigCanvas',
                                width: 343,
                                height: 180,
                            }}
                        />
                    )}

                    {formik.touched.signature_file &&
                        formik.errors.signature_file && (
                            <span className="error">
                                {formik.errors.signature_file?.toString()}
                            </span>
                        )}
                    {!formik?.values?.signature_file?.includes('https') && (
                        <div
                            onClick={() => {
                                signatureRef?.current?.clear();
                            }}
                            className="undo-image"
                        >
                            <img src="/img/undo.svg" alt="undo" />
                        </div>
                    )}
                </div>
                <div className="mt-10">
                    <Button
                        onClick={() => navigate('/meeting-minutes')}
                        sx={{
                            padding: '6px 18px',
                            height: '44.5px',
                            width: '166.5px',
                            borderColor: 'rgba(33, 84, 147, 0.30)',
                            color: '#215493',
                            borderRadius: '8px',
                            marginRight: '4px',
                        }}
                        variant="outlined"
                    >
                        戻る
                    </Button>
                    <Button
                        disabled={meetingMinutes.is_signed}
                        type="submit"
                        sx={{
                            padding: '6px 18px',
                            width: '166.5px',
                            marginLeft: '4px',
                            backgroundColor: '#215493',
                            borderColor: '#215493',
                            color: '#fff',
                            height: '44.5px',
                            borderRadius: '8px',
                        }}
                        variant="contained"
                    >
                        登録
                    </Button>
                </div>
            </form>
        </StylesCreateMeetingMinutes>
    );
};

const CreateInternalOJT = (props: ReceivedProps) => {
    return <CreateInternalOJTLayout {...useCreateMeetingMinutes(props)} />;
};

export default CreateInternalOJT;
