import moment from 'moment';
import { useEffect, useState, FocusEventHandler, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from 'react-hooks-use-modal';
import {
    Navigate,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import invoiceApi from 'services/invoiceApi';
import quoteApi from 'services/quoteApi';
import quoteService from 'services/quoteService';
import {
    alertError,
    alertSuccess,
    alertSuccessPopup,
} from 'utils/helper/appHelper';
import querystring from 'query-string';
import dayjs from 'dayjs';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import companyInfoService from 'services/companyInfoService';
interface ListData {
    id: number;
    user_name: string;
    avatar_url: string;
    address: string;
    status: string;
    created_at: string;
    quote_type: string;
}

const fakeData = [
    {
        number: 1,
        content: '内装工事',
        unit: '式',
        quantity: '01',
        unit_price: 3020,
        amount_of_money: 3020,
        tax_rate: 10,
        amount_with_tax: 3020,
    },
    {
        number: 2,
        content: 'トイレ工事',
        unit: '式',
        quantity: '01',
        unit_price: 3020,
        amount_of_money: 3020,
        tax_rate: 10,
        amount_with_tax: 3020,
    },
    {
        number: 3,
        content: '電気工事',
        unit: '式',
        quantity: '01',
        unit_price: 3020,
        amount_of_money: 3020,
        tax_rate: 10,
        amount_with_tax: 3020,
    },
    {
        number: 4,
        content: '水道工事',
        unit: '式',
        quantity: '01',
        unit_price: 3020,
        amount_of_money: 3020,
        tax_rate: 10,
        amount_with_tax: 3020,
    },
];

export type ReceivedProps = Record<never, never>;
const useInvoiceList = (props: ReceivedProps) => {
    const [invoiceList, setInvoiceList]: any = useState([]);
    const location = useLocation();
    const [arrItem, setArrItem] = useState([1]);
    const [invoice, setInvoice]: any = useState();
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [defaultValue, setDefaultValue] = useState({
        content: '',
        unit: '',
        quantity: '',
        price: '',
        tax: '',
    });
    const [totalBeforeTax, setTotalBeforeTax] = useState(0);
    const [totalAfterTax, setTotalAfterTax] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const queryObj = querystring.parse(location.search);
    const [isCheckSend, setIsCheckSend] = useState(false);
    const navigate = useNavigate();
    const onChangeDefault = (evt) => {
        const name = evt.target.name;
        setDefaultValue({ ...defaultValue, [name]: evt.target.value });
    };
    const {
        control,
        handleSubmit,
        watch,
        register,
        getValues,
        setError,
        setValue,
        formState: { errors },
        resetField,
        clearErrors,
    } = useForm();
    const param = useParams();

    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        }
    };

    const getDetail = async (arrEdit?) => {
        try {
            const res = await invoiceApi.getInvoiceDetail(
                user,
                queryObj?.invoice_id,
            );
            if (res) {
                const data = res.data.invoice;
                setInvoice(data);
                setValue('companyName', data.company_name);
                setValue('address', data.company_address);
                setValue('bankAccount', data.company_bank_account);
                setValue('customer', data.customer_name);
                setValue('address2', data.customer_address);
                setValue('callNum', data.invoice_number);
                if (data.payment_date) {
                    setValue('paymentDate', moment(data.payment_date));
                }
                if (data.invoice_date) {
                    setValue('date', moment(data.invoice_date));
                }
                let arrTmp: any = [];
                if (arrEdit) {
                    arrTmp = [...arrEdit];
                } else {
                    arrTmp = [...arrItem];
                }
                setTotalBeforeTax(data.total_before_tax);
                setTotalAfterTax(data.total_after_tax);
                setTotalTax(data.total_tax);
                if (data.invoice_details.length > 0) {
                    const resCount = data.invoice_details.length > 1 ? 1 : 0;
                    for (let i = 0; i < data.invoice_details.length; i++) {
                        const countTmp = arrTmp[arrTmp.length - 1] || 0;
                        arrTmp.push(countTmp + 1);
                        setValue(
                            `content.${i}`,
                            data.invoice_details[i]
                                ? data.invoice_details[i].content
                                : '',
                        );
                        setValue(
                            `price.${i}`,
                            data.invoice_details[i]
                                ? String(
                                      data.invoice_details[
                                          i
                                      ].price.toLocaleString('en-US'),
                                  )
                                : '',
                        );
                        setValue(
                            `quantity.${i}`,
                            data.invoice_details[i]
                                ? String(
                                      data.invoice_details[
                                          i
                                      ].quantity.toLocaleString('en-US'),
                                  )
                                : '',
                        );
                        setValue(
                            `tax.${i}`,
                            data.invoice_details[i]
                                ? data.invoice_details[i].tax
                                : '',
                        );
                        setValue(
                            `unit.${i}`,
                            data.invoice_details[i]
                                ? data.invoice_details[i].unit
                                : '',
                        );
                    }
                }
                setArrItem(arrTmp);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const getCompanyOwnerInfo = useCallback(async () => {
        try {
            const data = await companyInfoService.getCompanyInfo();
            if (data) {
                setValue('companyName', data.owner_company?.company_name || '');
                setValue('address', data.owner_company?.address || '');
                setValue('bankAccount', data.owner_company?.bank_number || '');
            }
        } catch (error) {
            //
        }
    }, []);

    const getQuoteDetail = async () => {
        try {
            const res = await quoteApi.getQuoteDetail(param.id);
            if (res) {
                const data = res.data.quote;
                setValue('address2', data.address);
                if (data?.customer?.last_name && data?.customer?.first_name) {
                    setValue(
                        'customer',
                        `${data?.customer?.last_name} ${data?.customer?.first_name}`,
                    );
                }
                setValue('callNum', `${param.id}`);
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    const calTotal = () => {
        let totalBFTax = 0;
        let totalTax = 0;
        let totalAfterTax = 0;
        if (getValues('price').length > 0) {
            for (let i = 0; i < getValues('price').length; i++) {
                let price = getValues(`price.${i}`)
                    ? getValues(`price.${i}`).replace(/,/g, '')
                    : '';
                let quantity = getValues(`quantity.${i}`)
                    ? getValues(`quantity.${i}`).replace(/,/g, '')
                    : '';
                // console.log('quantity replace', quantity.replace(/,/g, ''))
                if (
                    Number(price) > -1 &&
                    Number(getValues(`tax.${i}`)) > -1 &&
                    Number(quantity) > -1
                ) {
                    totalBFTax += Number(price) * Number(quantity);
                    totalTax +=
                        (Number(getValues(`tax.${i}`)) *
                            Number(quantity) *
                            Number(price)) /
                        100;
                    totalAfterTax +=
                        Number(price) * Number(quantity) +
                        (Number(getValues(`tax.${i}`)) *
                            Number(quantity) *
                            Number(price)) /
                            100;
                }
            }
            // console.log('totalBFTax', totalBFTax)
        }
        setTotalBeforeTax(totalBFTax);
        setTotalAfterTax(totalAfterTax);
        setTotalTax(totalTax);
    };

    const submitData = async (value) => {
        const arrTmp: any = [];
        if (
            value.content.length > 0 ||
            value.unit.length > 0 ||
            value.price.length > 0 ||
            value.quantity.length > 0
        ) {
            for (let i = 0; i < value.content.length; i++) {
                if (
                    value?.content[i] &&
                    value?.content[i]?.length > 0 &&
                    value?.unit[i] &&
                    value?.unit[i]?.length > 0 &&
                    value.quantity[i] &&
                    value.price[i]
                ) {
                    arrTmp.push({
                        content: value.content[i],
                        unit: value.unit[i] || '式',
                        quantity: value.quantity[i].replace(/,/g, ''),
                        price: value.price[i].replace(/,/g, ''),
                        tax: value.tax[i] || 0,
                    });
                } else {
                    alertError(
                        '内容、数量、単位、単価を入力する必要があります。',
                    );
                    return;
                }
            }
        }

        if (Number(totalAfterTax) >= 50 && Number(totalAfterTax) <= 99999999) {
            const body: any = {
                quote_id: param.id,
                company_name: getValues('companyName'),
                company_address: getValues('address'),
                company_bank_account: getValues('bankAccount'),
                payment_date: moment(getValues('paymentDate')).format(
                    'YYYY-MM-DD',
                ),
                invoice_date: moment(getValues('date')).format('YYYY-MM-DD'),
                customer_name: getValues('customer'),
                customer_address: getValues('address2'),
                invoice_number: getValues('callNum'),
                invoice_details_attributes: arrTmp,
            };
            if (isCheckSend) {
                body.is_send_message = true;
            }
            setArrItem([1]);
            if (queryObj.invoice_id !== 'null') {
                try {
                    const res = await invoiceApi.updateInvoice(
                        user,
                        body,
                        queryObj.invoice_id,
                    );
                    if (res) {
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        getDetail([]);
                        navigate(-1);
                    }
                } catch (error) {
                    console.log('error', error);
                }
            } else {
                try {
                    const res = await invoiceApi.createInvoice(user, body);
                    if (res) {
                        const data = res.data.invoice;
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        navigate(-1);
                    }
                } catch (error) {
                    console.log('error', error);
                }
            }
            setIsCheckSend(false);
        } else {
            alertError(
                '合計注文金額は¥50から¥99,999,999までの間である必要があります。',
            );
        }
        setIsCheckSend(false);
    };
    const fetchInvoice = async () => {
        try {
            setInvoiceList(fakeData);
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        if (queryObj?.invoice_id !== 'null') {
            getDetail([]);
        } else {
            const curTime = new Date();
            setValue(`date`, new Date());
            if (curTime.getMonth() === 11) {
                setValue(
                    `paymentDate`,
                    new Date(curTime.getFullYear() + 1, 1, 0),
                );
            } else {
                setValue(
                    `paymentDate`,
                    new Date(curTime.getFullYear(), curTime.getMonth() + 2, 0),
                );
            }
            setValue(`unit.0`, '式');
            setValue(`tax.0`, 10);
            getCompanyOwnerInfo();
            getQuoteDetail();
        }
    }, [queryObj?.invoice_id]);

    // useEffect(() => {
    //     if (arrItem.length > 1) {
    //         console.log('meet condition')
    //         calTotal()
    //     }
    // }, [arrItem])

    const handleBlur = () => {
        setArrItem([...arrItem]);
        calTotal();
    };

    const onDelete = (index) => {
        const filterArrItem = arrItem.filter((item, ind) => ind !== index);
        const contentFilter = getValues('content').filter(
            (item, ind) => ind !== index,
        );
        const unitFilter = getValues('unit').filter(
            (item, ind) => ind !== index,
        );
        const priceFilter = getValues('price').filter(
            (item, ind) => ind !== index,
        );
        const quantityFilter = getValues('quantity').filter(
            (item, ind) => ind !== index,
        );
        const taxFilter = getValues('tax').filter((item, ind) => ind !== index);
        const arrTmp: any = [];
        for (let i = 0; i < filterArrItem.length; i++) {
            const countTmp = arrTmp[arrTmp.length - 1] || 0;
            arrTmp.push(countTmp + 1);
        }
        setArrItem([...arrTmp]);
        setValue('content', [...contentFilter]);
        setValue('unit', [...unitFilter]);
        setValue('quantity', [...quantityFilter]);
        setValue('price', [...priceFilter]);
        setValue('tax', [...taxFilter]);
        // if (!getValues(`quantity.${arrTmp.length - 1}`)) {
        //     setValue(`content.${arrTmp.length - 1}`, '')
        //     setValue(`unit.${arrTmp.length - 1}`, '')
        //     setValue(`quantity.${arrTmp.length - 1}`, '')
        //     setValue(`price.${arrTmp.length - 1}`, '')
        //     setValue(`tax.${arrTmp.length - 1}`, '')
        // }
        calTotal();
    };

    const onAddArr = () => {
        // if (getValues('content')[arrItem.length - 1] && getValues('unit')[arrItem.length - 1] && getValues('quantity')[arrItem.length - 1]
        //     && getValues('price')[arrItem.length - 1] && getValues('tax')[arrItem.length - 1]) {
        //     const arrTmp = [...arrItem]
        //     arrTmp.push(arrItem[arrItem.length - 1] + 1)
        //     setArrItem(arrTmp)
        // }
        const arrTmp = [...arrItem];
        if (arrItem.length > 0) {
            arrTmp.push(arrItem[arrItem.length - 1] + 1);
            setValue(`tax.${arrTmp.length - 1}`, 10);
            setValue(`unit.${arrTmp.length - 1}`, '式');
            setValue(`content.${arrTmp.length - 1}`, undefined);
        } else {
            arrTmp.push(1);
            setValue(`unit.0`, '式');
            setValue(`content.0`, undefined);
            setValue(`tax.0`, 10);
        }
        setArrItem(arrTmp);
    };

    return {
        ...props,
        invoiceList,
        control,
        handleSubmit,
        watch,
        register,
        getValues,
        setError,
        setValue,
        resetField,
        clearErrors,
        submitData,
        errors,
        defaultValue,
        setDefaultValue,
        onChangeDefault,
        arrItem,
        setArrItem,
        onAddArr,
        totalBeforeTax,
        totalAfterTax,
        totalTax,
        handleBlur,
        onDelete,
        handleValidateWhitespace,
        invoice,
        setIsCheckSend,
    };
};
export type Props = ReturnType<typeof useInvoiceList>;

export default useInvoiceList;
